import { MotionValue, useInView, useMotionValue } from 'framer-motion';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
// import { ScrollTrigger } from 'gsap/scrolltrigger';
import clamp from 'lodash/clamp';
import React, { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { isMobile, isTablet } from 'react-device-detect';
import useIsomorphicLayoutEffect from '../UseIsomorphicLayoutEffect/UseIsomorphicLayoutEffect';

const ScrollTriggerContext = React.createContext<MotionValue>(null);

const useScrollTrigger = () => useContext(ScrollTriggerContext);

const DEFAULT_OPTIONS = {
  pin: true,
  scrub: true,
  // start: 'top top',
  // end: 'bottom center',
  start: 'top top',
  end: 'bottom top',
};

export type ScrollTriggerProviderProps = {
  componentName?: string;
  debug?: boolean;
  options?: Omit<gsap.plugins.ScrollTriggerInstanceVars, 'markers' | 'trigger' | 'onUpdate'>;
};

const ScrollTriggerProvider: React.FC<ScrollTriggerProviderProps> = ({
  children,
  componentName,
  debug = false,
  options = {},
}) => {
  const [runScroller, setRunScroller] = useState(false);
  const refScrollTrigger = useRef<HTMLDivElement>(null);
  const isInViewScrollTrigger = useInView(refScrollTrigger, {
    amount: 0.2,
    once: true,
  });

  const refTimeline = useRef<gsap.core.Timeline>();

  const progress = useMotionValue(0);

  const [mobile, setMobile] = useState(false);
  const [tablet, setTablet] = useState(false);
  const [desktop, setDesktop] = useState(false);

  const checkScreenSize = () => {
    if (isMobile) {
      setMobile(true);
      if (isTablet) setTablet(true);
      setDesktop(false);
    } else {
      setMobile(false);
      setTablet(false);
      setDesktop(true);
    }
  };

  useIsomorphicLayoutEffect(() => {
    checkScreenSize();
    window.addEventListener('resize', checkScreenSize);

    return () => window.removeEventListener('resize', checkScreenSize);
  }, []);

  // useIsomorphicLayoutEffect(() => {
  //   if (componentName != 'ExperienceAppSection') {
  //     console.log('componentName', componentName);
  //     setRunScroller(true);
  //   }
  // }, []);
  // useIsomorphicLayoutEffect(() => {
  //   setRunScroller(true);
  // }, [isInViewScrollTrigger]);

  useIsomorphicLayoutEffect(() => {
    ScrollTrigger.config({
      ignoreMobileResize: true,
    });
    ScrollTrigger.normalizeScroll();
    gsap.registerPlugin(ScrollTrigger);

    console.log('progress', progress.get());

    if (refScrollTrigger.current) {
      refTimeline.current = gsap.timeline({
        scrollTrigger: {
          ...DEFAULT_OPTIONS,
          ...options,
          pin: componentName == 'ExperienceAppSection' ? true : componentName == 'CardStacking' ? false : desktop,
          markers: debug,
          trigger: refScrollTrigger.current,
          onUpdate: (instance) => {
            console.log('progress-instance', instance.progress);
            progress.set(clamp(instance.progress, 0, 1) * 20000);
          },
          onLeave: (instance) => {
            progress.set(40000);
          },
        },
      });
    }

    return () => {
      // Kill and clear the timeline and scrolltrigger instance when updated/unmounted.
      refTimeline.current?.scrollTrigger?.kill();
      refTimeline.current?.kill();
      refTimeline.current?.clear();
    };
  }, [isInViewScrollTrigger]);

  return (
    <div ref={refScrollTrigger}>
      <ScrollTriggerContext.Provider value={progress}>{children}</ScrollTriggerContext.Provider>
    </div>
  );
};

export { ScrollTriggerProvider, useScrollTrigger };
