import 'swiper/css';
import 'swiper/css/pagination';

import {
  motion,
  useAnimation,
  useInView,
  useMotionValue,
  useMotionValueEvent,
  useScroll,
  useTransform,
} from 'framer-motion';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import React, { useContext, useState } from 'react';
import { useEffect, useRef } from 'react';
import { isMobile, isTablet } from 'react-device-detect';
import { useTina } from 'tinacms/dist/react';

const HomeSectionComponent = dynamic(() => import('../components/HomeSection/HomeSection'), {
  loading: () => <p>Loading...</p>,
});
const OfferSectionComponent = dynamic(() => import('../components/OfferSection/OfferSection'), {
  loading: () => <p>Loading...</p>,
});
const CardStackingSectionComponent = dynamic(() => import('../components/CardStackingSection/CardStackingSection'), {
  loading: () => <p>Loading...</p>,
});
const ExperienceAppSectionComponent = dynamic(() => import('../components/ExperienceAppSection/ExperienceAppSection'), {
  loading: () => <p>Loading...</p>,
  ssr: false,
});
const ReviewSectionComponent = dynamic(() => import('../components/ReviewSection/ReviewSection'), {
  loading: () => <p>Loading...</p>,
  ssr: false,
});
import { ScrollTriggerProvider } from '../components/ScrollHandler/ScrollTriggerProvider';
import { SectionContext } from '../components/SectionContext/SectionContext';
import styles from '../styles/Home.module.scss';
import client from '../tina/__generated__/client';

export async function getStaticProps({ locale }) {
  console.log('Homepage getStaticProps locale', locale);
  if (!locale) {
    locale = 'el';
  }
  let data = {};
  let query = {};
  let variables = { relativePath: `${locale}/index.yaml` };
  try {
    const res = await client.queries.homepage(variables);
    query = res.query;
    data = res.data;
    variables = res.variables;
  } catch (e) {
    // swallow errors related to document creation
    console.error('no page data found error', e);
  }

  return {
    props: {
      variables: variables,
      data: data,
      query: query,
      //myOtherProp: 'some-other-data',
    },
  };
}

export default function HomePage(props) {
  const { data } = useTina({
    query: props.query,
    variables: props.variables,
    data: props.data,
  });
  const pageData = data?.homepage;

  const router = useRouter();
  const { locale } = router;
  const t = pageData;
  const [dashboardURLLogin, setDashboardURLLogin] = useState('https://app.whiteclover.uk/dashboard/login');
  const [dashboardURLRegister, setDashboardURLRegister] = useState(
    'https://app.whiteclover.uk/dashboard/create-account',
  );

  useEffect(() => {
    setDashboardURLLogin(
      window.location.host.includes('localhost')
        ? 'http://localhost:3000/dashboard/login'
        : `https://app.${window.location.host}/dashboard/login`,
    );

    setDashboardURLRegister(
      window.location.host.includes('localhost')
        ? 'http://localhost:3000/dashboard/create-account'
        : `https://app.${window.location.host}/dashboard/create-account`,
    );
  }, []);

  const openInNewTab = (url: string) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };
  // const [scope, animate] = useAnimate();
  const [mobile, setMobile] = useState(false);
  const [desktop, setDesktop] = useState(true);
  const [maxWidth, setMaxWidth] = useState(1000);
  const [maxHeight, setMaxHeight] = useState(1000);

  const checkScreenSize = () => {
    setMaxWidth(window.innerWidth);
    setMaxHeight(window.innerHeight);
    if (window.innerWidth <= 768) {
      setMobile(true);
      setDesktop(false);
    } else {
      setMobile(false);
      setDesktop(true);
    }
  };

  useEffect(() => {
    checkScreenSize();
    setTimeout(() => {
      checkScreenSize();
    }, 5000);
    window.addEventListener('resize', checkScreenSize);

    return () => window.removeEventListener('resize', checkScreenSize);
  }, []);

  const ref = useRef(null);
  const refTwo = useRef(null);
  const mobile1Ref = useRef(null);
  const mobileCoupleInPhoneRef = useRef(null);
  const isInView = useInView(ref, { amount: 'all', once: true });
  const isInViewSection = useInView(refTwo, {
    amount: mobile ? 0.8 : 'all',
    // margin: mobile ? '100px' : '0px',
    once: mobile,
  });

  const { activeSection, setActiveSection } = useContext(SectionContext);

  const homeSectionRef = useRef(null);
  const isInViewHomeSection = useInView(homeSectionRef, {
    amount: 0.6,
  });
  useEffect(() => {
    if (isInViewHomeSection) {
      setActiveSection('home-section');
    }
  }, [isInViewHomeSection]);

  const offerSectionRef = useRef(null);
  const isInViewOfferSection = useInView(offerSectionRef, {
    amount: 0.6,
  });
  useEffect(() => {
    if (isInViewOfferSection) {
      setActiveSection('offer-section');
    }
  }, [isInViewOfferSection]);

  const weddingPlannerRef = useRef(null);
  const isInViewWeddingPlanner = useInView(weddingPlannerRef, {
    amount: 0.2,
  });
  useEffect(() => {
    if (isInViewWeddingPlanner) {
      setActiveSection('wedding-planner');
    }
  }, [isInViewWeddingPlanner]);

  const guestAppRef = useRef(null);
  const isInViewGuestApp = useInView(guestAppRef, {
    amount: 0.4,
    once: false,
  });
  useEffect(() => {
    if (isInViewGuestApp) {
      setActiveSection('guest-app');
    } else if (activeSection !== 'your-kind-of-story') {
      setActiveSection('wedding-planner');
    }
  }, [isInViewGuestApp]);

  const reviewSectionRef = useRef(null);
  const coupleInPhoneFixedRef = useRef(null);
  const isInViewReviewSection = useInView(reviewSectionRef, {
    amount: 0.4,
    // margin: '80px',
    once: false,
  });
  useEffect(() => {
    if (isInViewReviewSection) {
      setActiveSection('your-kind-of-story');
    }
  }, [isInViewReviewSection]);

  useEffect(() => {
    console.log('review section is in view', isInViewReviewSection);
    // desktop
    if (
      coupleInPhoneFixedRef.current &&
      isInViewReviewSection &&
      coupleInPhoneFixedRef.current.style.position == 'fixed'
    ) {
      coupleInPhoneFixedRef.current.style.position = 'absolute';
      // coupleInPhoneFixedRef.current.style.top = `${maxTopCoupleInPhone}px`;
      const scrollHeight = scrollYCoupleInPhone;
      coupleInPhoneFixedRef.current.style.top = `${
        scrollHeight + (maxHeight - mobile1Ref?.current?.getBoundingClientRect().height) / 2
      }px`;
    } else if (coupleInPhoneFixedRef.current && !isInViewReviewSection && isInViewGuestApp) {
      coupleInPhoneFixedRef.current.style.position = 'fixed';
      coupleInPhoneFixedRef.current.style.top = `${
        (maxHeight - mobile1Ref?.current?.getBoundingClientRect().height) / 2
      }px`;
    }
  }, [isInViewReviewSection, isInViewGuestApp]);

  const [preventScroll, setPreventScroll] = useState(false);

  const [scrollHeight, setScrollHeight] = useState(50000);
  const [scrollPosition, setScrollPosition] = useState(0);
  const scrollYProgress = useMotionValue(scrollPosition);
  const [deltaY, setDeltaY] = useState(0);
  // const [scrollHeightCoupleInPhoneSticky, setScrollHeightCoupleInPhoneSticky] = useState(4000);
  const { scrollY: scrollYProgressAfterPhoneAnimation } = useScroll();

  const [scrollYCoupleInPhone, setScrollYCoupleInPhone] = useState(0);
  useMotionValueEvent(scrollYProgressAfterPhoneAnimation, 'change', (latest) => {
    setScrollYCoupleInPhone(latest);
  });
  const [maxTopCoupleInPhone, setMaxTopCoupleInPhone] = useState(0);
  useEffect(() => {
    if (mobileCoupleInPhoneRef?.current?.getBoundingClientRect().y > 0) {
      setMaxTopCoupleInPhone(mobileCoupleInPhoneRef?.current?.getBoundingClientRect().y);
    }
  }, [mobileCoupleInPhoneRef?.current?.getBoundingClientRect().y]);

  // const scrollYCoupleInPhone = useTransform(
  //   scrollYProgressAfterPhoneAnimation,
  //   [maxTopCoupleInPhone, maxTopCoupleInPhone * 2],
  //   [maxTopCoupleInPhone, maxTopCoupleInPhone + maxHeight / 2],
  // );

  const marginTop = 100; // 100 is for mobile margin top equal to .animationSequence class

  const handleScroll = (deltaY) => {
    deltaY = isMobile || isTablet ? deltaY * 2 : deltaY;
    setDeltaY(deltaY);
    console.log('ela1', refTwo.current.getBoundingClientRect());
    console.log('ela2', window.scrollY);
    setScrollHeight(window.scrollY);
    console.log('ela3', deltaY);
    const newPosition = scrollPosition + deltaY;
    setScrollPosition(newPosition);
    console.log(`New position is ${newPosition}`);

    if (newPosition <= 0) {
      console.log('MIKROTERO TOU MIDEN');
      setPreventScroll(false);
      document.body.style.overflow = 'scroll';
      setScrollPosition(0);
    }

    if (newPosition >= 11500) {
      console.log('MEGALITERO TOU 11500');
      setPreventScroll(false);
      document.body.style.overflow = 'scroll';
      setScrollPosition(11500);
    }
    // Handle scroll position updates here, e.g., update other components or state
  };

  const [marginPhone1, setMarginPhone1] = useState(50);
  useEffect(() => {
    console.log('maxHeight', maxHeight);
    console.log('element height', mobile1Ref?.current?.getBoundingClientRect().height);
    // if mobile calculate the top of an element in order to position that in the middle of the screen vertically
    setMarginPhone1((maxHeight - mobile1Ref?.current?.getBoundingClientRect().height) / 2);

    // if desktop
  }, [mobile1Ref]);

  const yPhone1 = useTransform(
    scrollYProgress,
    [0, 3000],
    [maxHeight + marginPhone1 || scrollHeight, marginPhone1 || scrollHeight],
  );
  const scalePhone1 = useTransform(scrollYProgress, [3000, 5000], [1, 1.05]); // Modify the input and output ranges as needed
  const opacityFlash = useTransform(scrollYProgress, [5000, 7000, 9000], [0, 1, 0]);
  const opacityCoupleInPhone = useTransform(scrollYProgress, [7000, 9000, 11000, 11200], [0, 1, 1, 0]);
  const xCoupleInPhone = useTransform(
    scrollYProgress,
    [9000, 10500],
    [
      mobile1Ref?.current?.getBoundingClientRect().left,
      maxWidth -
        mobile1Ref?.current?.getBoundingClientRect().width -
        (maxWidth <= 1024 ? (maxWidth * 5) / 100 : (maxWidth * 10) / 100),
    ],
  );
  const opacityPhone1 = useTransform(scrollYProgress, [0, 1000, 8500, 9000], [0, 1, 1, 0]);
  const opacityCoupleImage = useTransform(scrollYProgress, [0, 9500, 10500], [1, 1, 0]);

  useEffect(() => {
    console.log('Element is in view: ', isInView);
  }, [isInView]);

  useEffect(() => {
    scrollYProgress.set(scrollPosition);
    // console.log('scrollYProgress updated');
  }, [scrollPosition]);

  const scrollToPosition = (y: number) => {
    // Flag to indicate if the scrollTo method was called
    let scrollToCalled = false;

    // Scroll event listener
    const onScroll = () => {
      if (scrollToCalled) {
        window.scrollTo({ top: refTwo.current.offsetTop - marginTop });
        // Prevent the default scroll behavior
        setTimeout(() => window.removeEventListener('scroll', onScroll), 500);
        // window.removeEventListener('scroll', onScroll);
        document.body.style.overflow = 'hidden';
        setPreventScroll(isInViewSection);
      }
    };

    // Attach the scroll event listener to the window
    window.addEventListener('scroll', onScroll);

    // Call the scrollTo method with the smooth behavior
    requestAnimationFrame(() =>
      window.scrollTo({
        top: y,
        behavior: 'auto',
      }),
    );

    // Set the flag to indicate that the scrollTo method was called
    scrollToCalled = true;
  };

  useEffect(() => {
    console.log('Animated Section is in view: ', isInViewSection);

    if (isInViewSection) {
      console.log('REF TWO CURRENT:', refTwo.current);
      scrollToPosition(refTwo.current.offsetTop - marginTop);
    }
    setPreventScroll(isInViewSection);
  }, [isInViewSection]);

  const controlCoupleInPhoneFixed = useAnimation();

  const [lastScrollMobile, setLastScrollMobile] = useState(0);
  useEffect(() => {
    console.log('RUN MOBILE', scrollPosition);
    let lastScrollMobileValue = lastScrollMobile;
    if (scrollPosition <= 11490) {
      lastScrollMobileValue = scrollYCoupleInPhone;
      setLastScrollMobile(lastScrollMobileValue);
    }
  }, [scrollYCoupleInPhone]);

  // useEffect(() => {
  //   alert(`${window.innerWidth}x${window.innerHeight}`);
  // }, []);

  return (
    <div>
      <ScrollTriggerProvider debug={false} options={{ end: '+=100%' }} componentName={'HomeSection'}>
        <div ref={homeSectionRef}>
          <HomeSectionComponent id={'home-section'} t={pageData}></HomeSectionComponent>
        </div>
      </ScrollTriggerProvider>
      <ScrollTriggerProvider debug={false} options={{ end: '+=300%' }} componentName={'OfferSection'}>
        <div ref={offerSectionRef}>
          <OfferSectionComponent id={'offer-section'} t={pageData}></OfferSectionComponent>
        </div>
      </ScrollTriggerProvider>

      <div ref={weddingPlannerRef}>
        <CardStackingSectionComponent
          id={'wedding-planner'}
          maxWidth={maxWidth}
          t={pageData}
        ></CardStackingSectionComponent>
      </div>

      <ScrollTriggerProvider debug={false} options={{ end: '+=300%' }} componentName={'ExperienceAppSection'}>
        <div ref={guestAppRef}>
          <ExperienceAppSectionComponent id={'guest-app'} t={pageData}></ExperienceAppSectionComponent>
        </div>
      </ScrollTriggerProvider>

      <div ref={reviewSectionRef}>
        <ReviewSectionComponent id={'your-kind-of-story'} />
      </div>

      <motion.section className={styles.letStart} key={'letStartSection'}>
        <motion.img
          key={'collagePhotos'}
          loading="lazy"
          src={`${process.env.CDN_PROVIDER_URL}/newassets/collage_photos_big.jpg`}
          alt="collage"
          viewport={{ amount: 0.7, once: true }}
          initial={{ filter: 'blur(0px)' }}
          whileInView={{
            filter: 'blur(4px)',
            transition: {
              duration: 2.5,
              ease: [0.42, 0, 0.58, 1],
            },
          }}
        />
        <motion.div
          key={'letStartTextBlock'}
          className={styles.textBlock}
          viewport={{ amount: 1, once: true }}
          whileInView={{
            opacity: 1,
            transition: {
              duration: 2,
              ease: [0.42, 0, 0.58, 1],
            },
          }}
          initial={{ opacity: 0 }}
        >
          <h2 className={'fridayH2'}>{t.letStartSection1}</h2>
          <button
            onClick={() => {
              openInNewTab(dashboardURLRegister);
            }}
          >
            <p className={'didacticP'}>{t.letStartSection2}</p>
          </button>
        </motion.div>
      </motion.section>
    </div>
  );
}
